/*!
  ______   ______    _    ____  _   _ _____ _____ ____  ____  _   _ _____ _   _    _    ____ ___ 
 | __ ) \ / / __ )  / \  / ___|| | | | ____| ____|  _ \/ ___|| | | | ____| | | |  / \  | __ )_ _|
 |  _ \\ V /|  _ \ / _ \ \___ \| |_| |  _| |  _| | |_) \___ \| |_| |  _| | |_| | / _ \ |  _ \| | 
 | |_) || | | |_) / ___ \ ___) |  _  | |___| |___|  _ < ___) |  _  | |___|  _  |/ ___ \| |_) | | 
 |____/ |_| |____/_/   \_\____/|_| |_|_____|_____|_| \_\____/|_| |_|_____|_| |_/_/   \_\____/___|
                                                                                                                                                                                                                                                                                                                                                                                                            
=========================================================
* BYBASHEERSHEHABI WEB - v1.0.0
=========================================================

* Product Page: https://www.bybasheershehabi.com/
* © 2023 ByBasheerShehabi LLC

* Designed and Coded ByBasheerShehabi LLC

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// React Imports
import { Fragment } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

// Pages Imports
import Home from "./pages/home";
import E404 from "./pages/404";
import About from "./pages/about";
import ScanGlobalPrivacyPolicy from "./pages/sgpp";
import ScanGlobalTnC from "./pages/sgtnc";

function App() {
  return (
    <Fragment>
      <BrowserRouter>
        <Routes>
          <Route path="*" element={<E404 />} />
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/sgtnc" element={<ScanGlobalTnC />} />
          <Route path="/sgpp" element={<ScanGlobalPrivacyPolicy />} />
        </Routes>
      </BrowserRouter>
    </Fragment>
  );
}

export default App;
