import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer
      className="main-footer rel z-1"
      style={{
        backgroundImage: "url(assets/images/footer/footer-bg-shape-two.png)",
      }}
    >
      <div className="container container-1290">
        <div className="footer-top pt-80 pb-60">
          <div className="row align-items-center">
            <div className="col-lg-4">
              <div className="footer-logo mb-20 wow fadeInRight delay-0-2s animated">
                <Link to="/">
                  <img src="assets/images/logos/footer-logo.png" alt="Logo" />
                </Link>
              </div>
            </div>
            <div className="col-lg-8 text-lg-end">
              <div className="social-style-four mb-20 wow fadeInLeft delay-0-2s animated">
                {/* <a href="www.facebook.com">
                  <i className="fab fa-facebook-f" /> <span>Facebook</span>
                </a>
                <a href="www.twitter.com">
                  <i className="fab fa-twitter" /> <span>Twitter</span>
                </a> */}
                <a href="https://www.instagram.com/bybasheershehabi/">
                  <i className="fab fa-instagram" /> <span>Instagram</span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-8">
            <div className="footer-left-content pt-80">
              <div className="lets-work mb-50 wow fadeInUp delay-0-2s animated">
                <img
                  src="assets/images/footer/lets-work.png"
                  alt="Let's Work Man"
                />
                <span>Let’s Work Together</span>
              </div>
              <div className="footer-contact-info wow fadeInUp delay-0-3s animated">
                <a
                  className="theme-btn style-three"
                  to="mailto:contact@bybasheershehabi.com"
                >
                  contact@bybasheershehabi.com{" "}
                  <i className="far fa-arrow-right" />
                </a>
                <a
                  className="theme-btn style-three phone-number"
                  to="tel:+37281463732"
                >
                  +1 (307) 381 0183 <i className="far fa-arrow-right" />
                </a>
                <a
                  className="theme-btn style-three phone-number"
                  to="https://goo.gl/maps/SQbbkt1FmePpxHo9A"
                >
                  30n North Gould Street, Sheridan, WY, USA <i className="far fa-arrow-right" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="row align-items-center">
            <div className="col-xl-8 col-lg-6">
              <div className="footer-bottom-menu pt-40 pb-35 rpb-0 wow fadeInRight delay-0-2s animated">
                <ul>
                  <li>
                    <Link to="/about">About</Link>
                  </li>
                  <li>
                    <Link to="/sgtnc">ScanGlobalTnC</Link>
                  </li>
                  <li>
                  <Link to="/sgpp">ScanGlobalPrivaycPolicy</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6">
              <div className="copyright-text text-lg-end pt-40 pb-35 rpt-10 wow fadeInLeft delay-0-2s animated">
                <p>
                  Copyright @2023, <Link to="/">ByBasheerShehabi LLC</Link> All
                  Rights Reserved
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
