/*!
  ______   ______    _    ____  _   _ _____ _____ ____  ____  _   _ _____ _   _    _    ____ ___ 
 | __ ) \ / / __ )  / \  / ___|| | | | ____| ____|  _ \/ ___|| | | | ____| | | |  / \  | __ )_ _|
 |  _ \\ V /|  _ \ / _ \ \___ \| |_| |  _| |  _| | |_) \___ \| |_| |  _| | |_| | / _ \ |  _ \| | 
 | |_) || | | |_) / ___ \ ___) |  _  | |___| |___|  _ < ___) |  _  | |___|  _  |/ ___ \| |_) | | 
 |____/ |_| |____/_/   \_\____/|_| |_|_____|_____|_| \_\____/|_| |_|_____|_| |_/_/   \_\____/___|
                                                                                                                                                                                                                                                                                                                                                                                                            
=========================================================
* BYBASHEERSHEHABI WEB - v1.0.0
=========================================================

* Product Page: https://www.bybasheershehabi.com/
* © 2023 ByBasheerShehabi LLC

* Designed and Coded ByBasheerShehabi LLC

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Global Styles Imports
import "../src/styles/globals.css";
import "./index.css";

// React Improts
import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";

// Module Improts
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
