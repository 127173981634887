/*!
  ______   ______    _    ____  _   _ _____ _____ ____  ____  _   _ _____ _   _    _    ____ ___ 
 | __ ) \ / / __ )  / \  / ___|| | | | ____| ____|  _ \/ ___|| | | | ____| | | |  / \  | __ )_ _|
 |  _ \\ V /|  _ \ / _ \ \___ \| |_| |  _| |  _| | |_) \___ \| |_| |  _| | |_| | / _ \ |  _ \| | 
 | |_) || | | |_) / ___ \ ___) |  _  | |___| |___|  _ < ___) |  _  | |___|  _  |/ ___ \| |_) | | 
 |____/ |_| |____/_/   \_\____/|_| |_|_____|_____|_| \_\____/|_| |_|_____|_| |_/_/   \_\____/___|
                                                                                                                                                                                                                                                                                                                                                                                                            
=========================================================
* BYBASHEERSHEHABI WEB - v1.0.0
=========================================================

* Product Page: https://www.bybasheershehabi.com/
* © 2023 ByBasheerShehabi LLC

* Designed and Coded ByBasheerShehabi LLC

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

const reportWebVitals = (onPerfEntry) => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    import("web-vitals").then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
      getCLS(onPerfEntry);
      getFID(onPerfEntry);
      getFCP(onPerfEntry);
      getLCP(onPerfEntry);
      getTTFB(onPerfEntry);
    });
  }
};

export default reportWebVitals;
