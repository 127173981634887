import { Link } from "react-router-dom";
import { Accordion } from "react-bootstrap";
import { Fragment } from "react";

const Nav = () => {
  return (
    <nav className="main-menu navbar-expand-lg">
      <Accordion>
        <div className="navbar-header py-10">
        <div className="mobile-logo">
            <Link to="/">
              <a>
                <img
                  src="assets/images/logos/logo.png"
                  alt="Logo"
                  title="Logo"
                />
              </a>
            </Link>
          </div>
        <div className="navbar-header py-10">
          {/* Toggle Button */}
          <Accordion.Toggle
            as={"button"}
            className="navbar-toggle"
            eventKey="navbar-toggle"
          >
            <span className="icon-bar" />
            <span className="icon-bar" />
            <span className="icon-bar" />
          </Accordion.Toggle>
        </div>
        </div>
        <Accordion.Collapse
          eventKey="navbar-toggle"
          className="navbar-collapse clearfix"
        >
          <Fragment>
            <ul className="navigation onepage clearfix">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/about">about</Link>
              </li>
              {/* <li>
                <Link to="/services">services</Link>
              </li>
              <li>
                <Link to="/projects">project</Link>
              </li> */}
            </ul>
          </Fragment>
        </Accordion.Collapse>
      </Accordion>
    </nav>
  );
};
export default Nav;
